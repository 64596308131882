import React from 'react';
import './style.scss'
import './App.css'
import Header from './Header/index'
import Footer from './footer/index'
import Switchlist from './switch/index'
export default class App extends React.Component{
    render(){
        return(
            <div className="container">
            <Header />
           <Switchlist />
            <Footer />
            </div>
        );
    }
}