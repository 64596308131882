import React, { Component } from 'react'
import {Link} from 'react-router-dom'

// import './style.css'
export default class index extends Component {
  render() {
    return (
      <div className="nav-container-sample">
          <div class="nav"> 
            <div class="nav-header">            
              <div class="nav-title">              
              NAAJ TROUVAILLE
              </div>
            </div>
            <div class="nav-btn">
              <label for="nav-check">
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <input type="checkbox" id="nav-check" />
            <div class="nav-links">
              <Link to='/Home' >Home</Link>
              <Link to='/AboutUs' >About Us</Link>
              <Link to='/Service' >Services</Link>
              <Link to='/ContactUs' >Contact Us</Link>
            </div>
          </div>
      </div>  
    //   <div className="header">
    //     <div className='logo'>LOGO</div>
    //     <div><Link to='/Home' className='navigation-links'>Home</Link></div>
    //     <div><Link to='/AboutUs' className='navigation-links'>About Us</Link></div>
    //     <div><Link to='/Service' className='navigation-links'>Services</Link></div>
    //     <div><Link to='/ContactUs' className='navigation-links'>Contact Us</Link></div>
    // </div>
    )
  }
}
