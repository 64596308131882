import React from 'react'
import {Switch,Route,Router} from 'react-router-dom'
import Home from '../Home/index'
import Aboutus from '../AboutUs/index'
import Contactus from '../Contact/index'
import Service from '../Service/service'
export default class Switchlist extends React.Component{
    render(){
        return(
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/Home' component={Home}  />
            <Route exact path='/AboutUs' component={Aboutus} />
            <Route exact path='/ContactUs' component={Contactus} />
            <Route exact path='/Service' component={Service} />
        </Switch>)
    }
}