import React from 'react'
// import Form from './form'

export default class App extends React.Component{
    render(){
        return(<div className="carousal">
                    <div className="contentin-carousal">
                    {/* <Form /> */}
                    </div>
                    <div className='left' />
                    <div className='right' />                
                </div>
        );
    }
}