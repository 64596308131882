import React from 'react'
import UnderConstruction from '../underConstruction/index'

export default class Contact extends React.Component{
    render(){
        return(
        <div className='content-window'>
        <UnderConstruction />
        </div>
        );
    }
}