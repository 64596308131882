import React,{Component} from 'react'
import icon from '../images/favicon.svg'

export default class Footer extends Component{
    render(){
        return(
            <div className='Footer'>
                <div className='section-1'>
                <img src={icon} width="30%"/>
                    <h5>Naaj Travels</h5>
                    <p>Travels & Tourism.</p>
                </div>
                <div className='section-2'/>
            </div>
        );
    }
}