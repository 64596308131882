import React from 'react'
import Carousal from '../carousal/index'
import UnderConstruction from '../underConstruction/index'

export default class Home extends React.Component{
    render(){
        return(
            <div>
                <Carousal />
                <div className='content-window'>
                <UnderConstruction />
                {/* <div className='home-content'>
                    <div className='home-heading'>NAAJ TROUVAILLE</div>
                    <div className='spacer'></div>
                    <div className='home-aboutUs'>kjdshf a dsjflkjasd flaskjdf lkjasdf lksadjf als;dkfjasd flkadsjf alsdkjfa dslkfja sldfjsaldjf lkasdf alsdkfj asldjf salkdfjsakdljf sakdljf saldjf asdlfj saldkjfldasjfldaksjfoiewaufdskaljlksamdvadlsk jfkdalsjfaeow flsdakmv aslfdjoaeiwjf lsadvcsaldjvaewjfoidas jvlkdsjv asioefju dalsjf asoi efjdsjfldaksmvoeaeiwjfadslvasjf jfda;jdffasld jcsaoi;uladskjvasfduewfusadvilasjfaieowajf vj alsdjfaeiwojf a</div>
                </div> */}
                </div>
            </div>            
        );
    }
}